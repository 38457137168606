import { connectStateResults } from 'react-instantsearch-dom'
import { ENTITAS_INDEX } from 'utils'

const StateResults = connectStateResults(
  ({ allSearchResults, setHitEntitas, setHitTassonomie, setQuery }) => {
    if (allSearchResults) {
      if (allSearchResults[ENTITAS_INDEX]) {
        setQuery(allSearchResults[ENTITAS_INDEX].query)
        setHitEntitas(allSearchResults[ENTITAS_INDEX].nbHits)
      }
      if (allSearchResults.giuntiscuola_prod_tassonomie) {
        setHitTassonomie(allSearchResults.giuntiscuola_prod_tassonomie.nbHits)
      }
    }
    return null
  }
)

export default StateResults
