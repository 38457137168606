import { Configure, Index, Hits, SearchBox } from 'react-instantsearch-dom'
import React, { useEffect, useState } from 'react'

// import CustomHits from 'components/algolia/Hits'
import Icon from 'components/atomic/Icon'
import { useRouter } from 'next/router'
import HitCorrelati from 'components/algolia/HitCorrelati'
import StateResults from 'components/algolia/StateResults'
import { ENTITAS_INDEX, getSearchTrends } from 'utils'
import dynamic from 'next/dynamic'

const CustomHits = dynamic(() => import('components/algolia/Hits'), {
  ssr: false, // Disable server-side rendering
})

const HeaderSearch = ({ page }) => {
  const router = useRouter()

  const [open, setOpen] = useState('ricerca-popup')
  const [actualPage, setActualPage] = useState('')
  const [query, setQuery] = useState(router.query.q)

  const [hitTassonomie, setHitTassonomie] = useState(0)
  const [hitEntitas, setHitEntitas] = useState(0)

  const [trendsOpen, setTrendsOpen] = useState(false)
  const [trends, setTrends] = useState([])

  useEffect(() => {
    if (actualPage === '' && page?.chiave) {
      setActualPage(page.chiave)
      setOpen('ricerca-popup')
      setTrendsOpen(false)
    } else {
      if (page?.chiave && actualPage !== page.chiave) {
        setActualPage(page.chiave)
        setOpen('ricerca-popup')
        setTrendsOpen(false)
      }
    }
  }, [page])

  useEffect(() => {
    if (router.query && router.query.q && query !== router.query.q) {
      setQuery(router.query.q)
    }
  }, [router.query])

  useEffect(() => {
    if (trendsOpen) {
      updateTrends()
    }
  }, [trendsOpen])

  function handleHeader(value) {
    if (!value && value.length === 0) {
      document.body.classList.remove('w-noscroll')
      setOpen('ricerca-popup')
    } else {
      document.body.classList.add('w-noscroll')
      setOpen('ricerca-popup ricerca-popup--open')
    }
  }

  function handleClose(e) {
    document.body.classList.remove('w-noscroll')
    setOpen('ricerca-popup')
    setQuery('')
  }

  function handleVediTutti(e) {
    router.push(`/ricerca?q=${query}`)
  }

  function handleSearchTrends(status) {
    setTrendsOpen(status)
  }

  async function updateTrends() {
    const data = await getSearchTrends()
    setTrends(data)
  }

  const hasToShow = (page && page.chiave && page.chiave !== 'ricerca') || !page

  return (
    <>
      <SearchBox
        reset={<></>}
        className="search"
        defaultRefinement={query}
        onChange={(event) => {
          setQuery(event.currentTarget.value)
          if (hasToShow) {
            handleHeader(event.currentTarget.value)
            handleSearchTrends(false)
          }
        }}
        onSubmit={(event) => {
          event.preventDefault()
          if (hasToShow) {
            handleVediTutti()
          }
        }}
        submit={
          <span
            onClick={(e) => {
              if (hasToShow) {
                handleVediTutti()
              }
            }}
            type="submit"
            className="search__submit"
          >
            <Icon id="search" />
          </span>
        }
        translations={{
          submitTitle: '',
          resetTitle: '',
          placeholder: 'Cosa stai cercando?',
        }}
        onClick={() => handleSearchTrends(!router.pathname.includes('ricerca') && !query)}
      />

      <div className={open}>
        <div className="ricerca-popup__close-container" onClick={handleClose}>
          <Icon id="close" classes="ricerca-popup__close" />
        </div>

        <div className="ricerca-popup__content u-stylish-scroll">
          <div className="ricerca-related">
            <Index indexName="giuntiscuola_prod_tassonomie">
              <StateResults
                setHitEntitas={setHitEntitas}
                setHitTassonomie={setHitTassonomie}
                setQuery={setQuery}
              />
              {hitTassonomie === 0 ? null : (
                <div className="w-container-small">
                  <p className="ricerca-related__title">Temi e discipline in evidenza</p>
                  <div className="ricerca-related__tags ricerca-related__tags--popup">
                    <Configure hitsPerPage={3} />
                    <Hits hitComponent={HitCorrelati} />
                  </div>
                </div>
              )}
            </Index>
          </div>
          {query || hitEntitas ? (
            <div className="w-container-small">
              <Index indexName={ENTITAS_INDEX}>
                <div className="ricerca-popup__results">
                  <div className="ricerca-popup__top">
                    <p className="ricerca-popup__title">Risultati in evidenza</p>
                    <a
                      onClick={handleVediTutti}
                      className="ricerca-popup__viewall button button--tertiary"
                    >
                      Vedi tutti i risultati <Icon id="arrow-right" classes="button__arrow" />
                    </a>
                  </div>
                  <CustomHits query={query} horizontal limit={4} inPopup />
                </div>
              </Index>
            </div>
          ) : (
            <div className="ricerca-popup__noresults">
              <div className="w-container-small">
                {!query ? <p>Nessuna ricerca selezionata</p> : <p>Nessun risultato trovato</p>}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={`ricerca-trends ${trendsOpen ? 'ricerca-trends--open' : ''}`}>
        <div className="ricerca-popup__close-container" onClick={() => handleSearchTrends(false)}>
          <Icon id="close" classes="ricerca-trends__close" />
        </div>
        <div className="ricerca-trends__content u-stylish-scroll">
          <div className="w-container-small">
            <p className="ricerca-trends__title">Trend di ricerca</p>
            <div className="ricerca-trends__list">
              {trends.map((t) => (
                <p
                  className="ricerca-trends__item"
                  onClick={() => {
                    window.location.href = `/ricerca?q=${t}` // forza SSR
                  }}
                  key={t}
                >
                  {t}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeaderSearch
